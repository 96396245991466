var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('validation-observer', {
    ref: "refFormReservation"
  }, [_vm.getSearchFlight ? _c('PassengerDetailClassBooking', {
    attrs: {
      "customer-id": _vm.customerId,
      "passenger-data-to-add": _vm.sortedPassengers,
      "data-search-flight": _vm.getSearchFlight,
      "selected-trip": _vm.selectedTrip
    },
    on: {
      "update:passengerDataToAdd": function updatePassengerDataToAdd($event) {
        _vm.sortedPassengers = $event;
      },
      "update:passenger-data-to-add": function updatePassengerDataToAdd($event) {
        _vm.sortedPassengers = $event;
      },
      "deletePassenger": _vm.handleDeletePassenger,
      "multipleQuantityPaxChange": _vm.handleMultipleQuantityPaxChange
    }
  }) : _vm._e(), _c('b-card', {
    staticClass: "mt-1 shadow",
    attrs: {
      "no-body": ""
    }
  }, [_c('BookingAgencyDetail', {
    attrs: {
      "agency-data": _vm.agencyData,
      "checkbox-customer-data-replace-agency-data": _vm.checkboxCustomerDataReplaceAgencyData,
      "is-role-f1": _vm.isRoleF1,
      "enable-send-employee-mail-airline": _vm.enableSendEmployeeMailAirline,
      "is-collapsed": _vm.isCollapsedAgencyAndContactDetail
    },
    on: {
      "update:agencyData": function updateAgencyData($event) {
        _vm.agencyData = $event;
      },
      "update:agency-data": function updateAgencyData($event) {
        _vm.agencyData = $event;
      },
      "update:checkboxCustomerDataReplaceAgencyData": function updateCheckboxCustomerDataReplaceAgencyData($event) {
        _vm.checkboxCustomerDataReplaceAgencyData = $event;
      },
      "update:checkbox-customer-data-replace-agency-data": function updateCheckboxCustomerDataReplaceAgencyData($event) {
        _vm.checkboxCustomerDataReplaceAgencyData = $event;
      },
      "toggleCollapse": _vm.toggleIsCollapsedAgencyAndContactDetail
    }
  }), _c('BookingContactDetail', {
    attrs: {
      "customer-data": _vm.customerData,
      "checkbox-customer-data-replace-agency-data": _vm.checkboxCustomerDataReplaceAgencyData,
      "is-role-f3": _vm.isRoleF3,
      "is-collapsed": _vm.isCollapsedAgencyAndContactDetail
    },
    on: {
      "update:customerData": function updateCustomerData($event) {
        _vm.customerData = $event;
      },
      "update:customer-data": function updateCustomerData($event) {
        _vm.customerData = $event;
      },
      "update:checkboxCustomerDataReplaceAgencyData": function updateCheckboxCustomerDataReplaceAgencyData($event) {
        _vm.checkboxCustomerDataReplaceAgencyData = $event;
      },
      "update:checkbox-customer-data-replace-agency-data": function updateCheckboxCustomerDataReplaceAgencyData($event) {
        _vm.checkboxCustomerDataReplaceAgencyData = $event;
      },
      "toggleCollapse": _vm.toggleIsCollapsedAgencyAndContactDetail,
      "resetAgencyData": _vm.resetAgencyData
    }
  })], 1), !_vm.isProduction ? _c('b-card', {
    attrs: {
      "header-class": "py-50 px-1",
      "body-class": "mx-1",
      "no-body": ""
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function fn() {
        return [_c('div', {
          staticClass: "d-flex align-items-center"
        }, [_c('img', {
          staticClass: "mr-50",
          attrs: {
            "src": require("@icons/edit-remark.svg"),
            "alt": "Remark",
            "width": "24px"
          }
        }), _c('span', {
          staticClass: "font-medium-1 font-weight-bolder"
        }, [_vm._v(" Remark ")])])];
      },
      proxy: true
    }], null, false, 3071105995)
  }, [_c('div', {
    staticClass: "mx-1 mb-75"
  }, [_c('b-form-textarea', {
    attrs: {
      "id": "textarea",
      "placeholder": "Thêm remark",
      "rows": "1"
    },
    model: {
      value: _vm.textRemark,
      callback: function callback($$v) {
        _vm.textRemark = $$v;
      },
      expression: "textRemark"
    }
  })], 1)]) : _vm._e(), !_vm.isProduction ? _c('b-card', {
    attrs: {
      "body-class": "px-50 py-1 shadow",
      "no-body": ""
    }
  }, [_c('b-input-group', {
    staticClass: "input-group-merge border-info rounded"
  }, [_c('b-input-group-prepend', {
    staticClass: "prepend_account_code",
    attrs: {
      "is-text": ""
    }
  }, [_c('span', [_vm._v("AccountCode: ")])]), _c('b-form-input', {
    staticClass: "pl-50 font-medium-2 text-body",
    attrs: {
      "id": "AccountCode",
      "placeholder": "Account Code..."
    },
    model: {
      value: _vm.accountCodeInput,
      callback: function callback($$v) {
        _vm.accountCodeInput = $$v;
      },
      expression: "accountCodeInput"
    }
  })], 1)], 1) : _vm._e(), _c('b-card', {
    staticClass: "mb-0",
    attrs: {
      "body-class": "p-75"
    }
  }, [_c('div', {
    staticClass: "d-flex-center"
  }, [['1G'].includes(_vm.airlineSearch) ? _c('b-button', {
    staticClass: "mr-1",
    attrs: {
      "variant": "info",
      "pill": ""
    },
    on: {
      "click": function click($event) {
        return _vm.handleOpenCalculatePriceModal('reservation');
      }
    }
  }, [_vm._v(" Tính giá ")]) : _vm._e(), _c('b-button', {
    staticClass: "px-2 px-md-3",
    attrs: {
      "variant": "warning",
      "pill": ""
    },
    on: {
      "click": _vm.handleValidateBooking
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('flight.createBooking')) + " ")])], 1)]), _c('ModalCalcPriceClassBooking', {
    attrs: {
      "modal-id": 'reservation'
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }